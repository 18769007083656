import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Product } from 'src/app/modals/product.model';
import { CartService } from '../../shared/services/cart.service';
import { WishlistService } from '../../shared/services/wishlist.service';
import { NgxSpinnerService } from "ngx-spinner";
export interface ResultInterface {
  Result: any;  
  HasError: string;  
}
@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.sass']
})
export class WishlistComponent implements OnInit {
 

  public product        :   Product[] = [];
  wishlistItems  :   Product[] = [];

  constructor(private cartService: CartService, private wishlistService: WishlistService, private spinner: NgxSpinnerService ) {
    this.spinner.show();
    this.initialize();
  }

  ngOnInit() {
    this.initialize();
  }

  public initialize() {
    this.wishlistService.getProducts().subscribe( (item:ResultInterface) => {
      if(item.HasError) {
        this.wishlistItems = [];
      } else {
        this.wishlistItems = item.Result;
      }
      console.log(item);
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
      }, 5000);
    });
  }

   // Add to cart
 public addToCart(product: Product,  quantity: number = 1) {
  if (quantity > 0)
   //this.cartService.addToCart(product,quantity);
   this.wishlistService.removeFromWishlist(product);
}

// Remove from wishlist
public removeItem(product: Product) {
 this.wishlistService.removeFromWishlist(product).subscribe(item => {   
  console.log(item);
  });
 this.initialize();
}

getUrl(option){
  var urlInfo = ['/'];
  option.Url.split('/').forEach(element => {
      urlInfo.push(element);
  });
  return urlInfo;
}

}
