import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountProvider } from '../../shared/services/account';
import { AppComponent } from "src/app/app.component";
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';

export interface ResultInterface {
  Result: any;  
  HasError: string;  
  Error: string;
}
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.sass']
})
export class MyAccountComponent implements OnInit {
  frmProfile : FormGroup;
  frmWork : FormGroup;
  frmPass : FormGroup;

  profileData : any = {};
  profilePhotoUrl : string = "assets/img/noavatar.png";
  user: {displayName?: string, email?: string, photoURL?: string} = {};
  addressList:any[]=[];

  submitted: boolean = false;
  error: any;
  loggedIn: any;
  logg: any;
  constructor(
    private fb: FormBuilder,
    public router: Router,
    private accountProvider: AccountProvider, 
    public snackBar: MatSnackBar,
    private titleService: Title
    ) {   
      this.titleService.setTitle('Hesabım - Depolaa');
        this.frmProfile = this.fb.group({
          firstname: ['', Validators.compose([Validators.maxLength(30), Validators.required])],
          lastname: ['', Validators.compose([Validators.maxLength(30), Validators.required])],
          username: ['', Validators.compose([Validators.maxLength(30), Validators.required])],
          email: ['', Validators.compose([Validators.maxLength(30), Validators.required,Validators.email])],
          mobile: ['',Validators.required]
        });
    
        this.frmWork = this.fb.group({
          university: [''],
          expertCategory: [''],
          name: [''],
          phone: [''],
          address: [''],
          taxNumber: [''],
          taxOffice: ['']
        });
    

      this.frmPass = this.fb.group({
        currentPassword: ['',Validators.compose([Validators.maxLength(12), Validators.required])],
        newPassword: ['',Validators.compose([Validators.maxLength(12), Validators.required])],
        newRePassword: ['',Validators.compose([Validators.maxLength(12), Validators.required])]
      });

    }

  ngOnInit() {
    this.logg = this.accountProvider.getUserInfo();     
    this.loggedIn = this.logg ? 3 : 0;
    console.log(this.logg);
    if(this.logg){
      AppComponent.memberId = this.logg.DataId;
      AppComponent.memberIsStudent = this.logg.IsStudent;  
      AppComponent.memberIdq = this.logg.MemberId;
        console.log(this.logg);
        
    } else {
      this.router.navigate(['/uye-girisi']);
    }
    this.accountProvider.getProfileInfo(this.logg.MemberId).subscribe((profileResult: ResultInterface)=>{
      console.log(profileResult);
      if(profileResult.HasError){
       // this.utilityProvider.doAlert('Hata !',profileResult.Error,'OK').present();
      }else{
        this.profileData = profileResult.Result;
        this.profilePhotoUrl = profileResult.Result.ProfilePhoto;
        this.setCurrentValuesToForm();

        
      }
    });
    this.accountProvider.getAddressList(AppComponent.memberIdq).subscribe((result: ResultInterface)=>{
      this.addressList = result.Result;
    });
  }

  setCurrentValuesToForm(){
    //Profile
    this.frmProfile.controls['firstname'].setValue(this.profileData.Profile.Firstname);
    this.frmProfile.controls['lastname'].setValue(this.profileData.Profile.Lastname);
    this.frmProfile.controls['username'].setValue(this.profileData.Profile.Username);
    this.frmProfile.controls['email'].setValue(this.profileData.Profile.Email);
    this.frmProfile.controls['mobile'].setValue(this.profileData.Profile.Mobile);
    //Work
    this.frmWork.controls['university'].setValue(this.profileData.Work.University);
    this.frmWork.controls['expertCategory'].setValue(this.profileData.Work.ExpertCategory);
    this.frmWork.controls['name'].setValue(this.profileData.Work.Name);
    this.frmWork.controls['phone'].setValue(this.profileData.Work.Phone);
    this.frmWork.controls['address'].setValue(this.profileData.Work.Address);
    this.frmWork.controls['taxNumber'].setValue(this.profileData.Work.TaxNumber);
    this.frmWork.controls['taxOffice'].setValue(this.profileData.Work.TaxOffice);
  }

  accountUpdate(){ 
    this.submitted=true;
    let model = {
      MemberId : AppComponent.memberIdq,
      Current:this.frmPass.value.currentPassword,
      New:this.frmPass.value.newPassword,
      ReNew:this.frmPass.value.newRePassword
    };
    if(model.New==model.ReNew){
      this.accountProvider.updateAccount(model).subscribe((result:ResultInterface)=>{
        this.error = null;
        if(result.HasError){
          this.error = result.Error;
        } else {
          this.snackBar.open('Şifreniz değiştirildi!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          this.frmPass.controls['currentPassword'].setValue("");
          this.frmPass.controls['newPassword'].setValue("");
          this.frmPass.controls['newRePassword'].setValue("");
        }        
      });    
    }else{ 
      this.error = "Şifreleriniz aynı olmalıdır!";
    }    
  }

  profileUpdate(){ 
    let model = {
      MemberId : AppComponent.memberIdq,
      Firstname:this.frmProfile.value.firstname,
      Lastname:this.frmProfile.value.lastname,
      Username:this.frmProfile.value.username,
      Email:this.frmProfile.value.email,
      Mobile:this.frmProfile.value.mobile 
    };
    this.accountProvider.updateProfile(model).subscribe((result: ResultInterface)=>{
      
      if(result.HasError){
        this.snackBar.open(result.Error, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        //this.utilityProvider.doAlert('Hata !',result.Error,'Ok').present();
      }else{
        this.accountProvider.setUserInfo(result.Result);
        this.snackBar.open('Profil bilgileriniz güncellendi', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      }
    });    
  }

  workUpdate(){
     
    let model = {
      MemberId : AppComponent.memberIdq,
      University:this.frmWork.value.university,
      ExpertCategory:this.frmWork.value.expertCategory,
      OfficeName:this.frmWork.value.name,
      OfficePhone:this.frmWork.value.phone,
      OfficeAddress:this.frmWork.value.address, 
      TaxNumber:this.frmWork.value.taxNumber,
      TaxOffice:this.frmWork.value.taxOffice,
    };
    this.accountProvider.updateWork(model).subscribe((result: ResultInterface)=>{     
      if(result.HasError){
        this.error = "Şifreleriniz aynı olmalıdır!";
      } else {
        this.snackBar.open('Bilgileriniz güncellendi!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      }
    });    
  }

  // onLogin() {     
     
  //   this.submitted=true;
  //   this.accountProvider.login(this.frmPass.value).subscribe( (result:ResultInterface)=>{
       
  //     if(result.HasError){        
  //       this.error = result.Error;
  //     }else{
  //       this.error = null;
  //       var memberId=result.Result.MemberId;
  //       AppComponent.memberId = result.Result.DataId;
  //       AppComponent.memberIsStudent =result.Result.IsStudent;
  //       console.log(result.Result);
        
  //       var setuser = this.accountProvider.setUserInfo(result.Result);
  //         if(setuser) {
  //           setTimeout(()=>{ 
  //             this.accountProvider.status(true,'active',memberId).subscribe(result=>{});
  //             this.router.navigate(['/pages/uye-girisi']);
  //           },750);
  //         }
  //     }
  //   });   

  // }

}
