import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ContactComponent } from './contact/contact.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { OrdersComponent } from './orders/orders.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { FaqComponent } from './faq/faq.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { PointsComponent } from './points/points.component';
import { PaymentComponent } from './payment/payment.component';
import { AddressComponent } from './address/address.component';
import { MainComponent } from '../../components/main/main.component';
import { HomeComponent } from '../shop/home/home.component';

const routes: Routes = [
  {
    path: '',
    //component: MainComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'hakkimizda', component: AboutUsComponent },
      { path: 'kvkk', component: AboutUsComponent },
      { path: 'kullanici-sozlesmesi', component: AboutUsComponent },
      { path: 'gizlilik-politikasi', component: AboutUsComponent },
      { path: 'teslimat', component: AboutUsComponent },
      { path: 'iptal-ve-iade', component: AboutUsComponent },
      { path: 'cerez-politikasi', component: AboutUsComponent },
      { path: 'sepet', component: CartComponent },
      { path: 'siparis', component: CheckoutComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'iletisim', component: ContactComponent },
      { path: 'favoriler', component: WishlistComponent },
      { path: 'hesabim', component: MyAccountComponent },
      { path: 'siparisler', component: OrdersComponent },
      { path: 'parapuan', component: PointsComponent },
      { path: 'odeme', component: PaymentComponent },
      { path: 'adreslerim', component: AddressComponent },
      { path: 'uye-girisi', component: LoginComponent },
      { path: 'kayit-ol', component: RegisterComponent },
      { path: 'error', component: ErrorPageComponent },
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
