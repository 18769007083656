import { Component, OnInit, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { Product } from 'src/app/modals/product.model';

@Component({
  selector: 'app-paydialog',
  templateUrl: './paydialog.component.html',
  styleUrls: ['./paydialog.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class PayDialogComponent implements OnInit {
  public order; 

  @ViewChild('zoomImage', { static: true }) zoomImage;

  constructor( 
    private productsService: ProductService, 
    public dialogRef: MatDialogRef<PayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.order = data; 
      console.log(data);
      }

  ngOnInit() {

  }

  public close(): void {
    this.dialogRef.close();
  }


}
