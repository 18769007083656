// Product Tag
export type ProductTags = 'nike' | 'puma' | 'lifestyle' | 'caprese';

// Product Colors
export type ProductColor = 'white' | 'black' | 'red' | 'green' | 'purple' | 'yellow' | 'blue' | 'gray' | 'orange' | 'pink';

export class Product {
  Id?: number;
  AttrId?: string;
  AttrName?: string;
  AttrValue?: string;
  Discount?: string;
  FinalPrice?: string;
  ListPrice?: string;
  Logo?: string;
  Name?: string;
  NetPrice?: string;
  Price?: string;
  ProductId?: number;
  Quantity?: number;
  TaxPrice?: string;
  TaxRate?: string;
  IsFavorited?: boolean;
  BrandName?: string;
  PropSelection?: boolean;
  PropertyList?: any;
  MainCategory?: any;
  Category?: any;
  PictureList?: any;
  Url?: any;
  Code?: number;

  constructor(
    Id?: number,
    AttrId?: string,
    AttrName?: string,
    AttrValue?: string,
    Discount?: string,
    FinalPrice?: string,
    ListPrice?: string,
    Logo?: string,
    Name?: string,
    NetPrice?: string,
    Price?: string,
    ProductId?: number,
    Quantity?: number,
    TaxPrice?: string,
    TaxRate?: string,
    IsFavorited?: boolean,
    BrandName?: string,
    PropSelection?: boolean,
    PropertyList?: any,
    MainCategory?: any,
    Category?: any,
    PictureList?: any,
    Url?: any,
    Code?: number,
    // tags?: ProductTags[],
    // colors?: ProductColor[]
  ) {
    this.Id = Id;
    this.Url = Url;
    this.Code = Code;
    this.PictureList = PictureList;
  }

 }
 
  
