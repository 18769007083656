import { Component, OnInit, Input } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { CartService } from '../services/cart.service';
import { Observable } from 'rxjs';
import { CartItem } from 'src/app/modals/cart-item';
import { ProductService } from '../services/product.service';
import { AppComponent } from "src/app/app.component";

export interface ResultInterface {
  Result: any;  
}

@Component({
  selector: 'app-shopping-widgets',
  templateUrl: './shopping-widgets.component.html',
  styleUrls: ['./shopping-widgets.component.sass']
})
export class ShoppingWidgetsComponent implements OnInit {

  products: Product[];
  indexProduct: number;

  public sidenavMenuItems:Array<any>;

  @Input() shoppingCartItems: CartItem[] = [];

  constructor(private cartService: CartService, public productService: ProductService) { }

  ngOnInit() {
    setTimeout(() => {
      this.getTotalCart();
    }, 2000); 
  }
  public updateCurrency(curr) {
    this.productService.currency = curr;
  }


  public removeItem(item: CartItem) {
    //this.cartService.removeFromCart(item);
  }

  public getTotal(): number {
    //console.log(AppComponent.cartTot);
      return AppComponent.cartTot;
  }
  public getTotalCart() { 
 
    this.cartService.getMemberBasket().subscribe((result:ResultInterface)=>{
      if(result.Result) { 
        console.log("BASKET", result);
        AppComponent.cartTot = result.Result.BasketPrice;
      }
      // BankList: [{…}]
      // BasketId: "398931f6-7ebd-4844-9e56-73759b684e11"
      // BasketPrice: 749.2190804
      // CreditAmount: 0
      // Detail: (4) [{…}, {…}, {…}, {…}]
      // HasCredit: false
      // MemberId: 1
      // MemberPoint: 0
      // NetPrice: 666.71678
      // TaxPrice: 82.5023004
    });
     
    //AppComponent.cartTot = cartT;
  }
}
