import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountProvider } from '../../shared/services/account';
import { AppComponent } from "src/app/app.component";
import { MatDialog } from '@angular/material';
import { ForgetDialogComponent } from "./dialog/forget-dialog.component";

 
import { Router } from '@angular/router';
export interface ResultInterface {
  Result: any;  
  HasError: string;  
  Error: string;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  loginForm : FormGroup;
  submitted: boolean = false;
  error: any;
  loggedIn: any;
  logg: any;
  constructor(
    private fb: FormBuilder,
    public router: Router,
    public dialog: MatDialog, 
    private accountProvider: AccountProvider
    ) { 

      this.logg = this.accountProvider.getUserInfo();

     
        this.loggedIn = this.logg ? 3 : 0;

        if(this.logg){
          AppComponent.memberId = this.logg.DataId;
          AppComponent.memberIsStudent = this.logg.IsStudent;  
          this.router.navigate(['/']);
          console.log(this.logg);
            
        } else { 
        }
   

      this.loginForm = this.fb.group({
        email: ['', Validators.compose([Validators.maxLength(30), Validators.required,Validators.email])],
        password: ['', Validators.compose([Validators.maxLength(12), Validators.required])]
      });

    }

  ngOnInit() {
    this.logg = this.accountProvider.getUserInfo();
    if(this.logg){ 
      this.router.navigate(['/']);
    } else {
       
    }
  }

  onLogin() {     
     
    this.submitted=true;
    this.accountProvider.login(this.loginForm.value).subscribe( (result:ResultInterface)=>{
       
      if(result.HasError){        
        this.error = result.Error;
      }else{
        this.error = null;
        var memberId=result.Result.MemberId;
        AppComponent.memberId = result.Result.DataId;
        AppComponent.memberIsStudent =result.Result.IsStudent;
        console.log(result.Result);
        
        var setuser = this.accountProvider.setUserInfo(result.Result);
         if(setuser) {
            setTimeout(()=>{ 
              this.accountProvider.status(true,'active',memberId).subscribe(result=>{});
              this.router.navigate(['/']);
              window.location.reload();
            },750);
          }
      }
    });   

  }

  forget() {
  
    let memberInfo = this.accountProvider.getUserInfo();
    
    let dialogRef = this.dialog.open(ForgetDialogComponent, {
      data: {
      },
      width:'500px',
      panelClass: 'payment-dialog',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      
      setTimeout(() => {
         
      }, 750);        
    }); 
}

}
