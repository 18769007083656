import { Component, OnInit, AfterViewInit, AfterContentInit, ElementRef, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { Product } from 'src/app/modals/product.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppComponent } from "src/app/app.component";
import { skip } from 'rxjs/operators';
import { interval, fromEvent } from 'rxjs';

export interface ResultInterface {
  Result: any;  
  HasError: string;  
  Error: string;
}
@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class IframeComponent implements OnInit, AfterViewInit, AfterContentInit {
  //@ViewChild('iframe') iframe: ElementRef;
  //@ViewChild('iframe', { static: true }) iframe!: ElementRef<HTMLIFrameElement>;
  @ViewChild('iframe', { static: false }) iframeElement: ElementRef;


  public order; 
  url: string = "about:blank";
  frameUrl: SafeResourceUrl; // any = "about:blank";
  process: boolean = false;
  checkorder: boolean = false;
  constructor( 
    public sanitizer: DomSanitizer, 
    private productsService: ProductService, 
    public dialogRef: MatDialogRef<IframeComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.frameUrl= this.sanitizer.bypassSecurityTrustResourceUrl(data);
  }

  ngAfterViewInit() { 
    setTimeout(() => {
      
      let el = this.iframeElement.nativeElement;
      var newHref = el;   
      
      
       
      el.addEventListener('unload', function () {
        console.log('unload Event Fired');
        var newHref = el.contentWindow.location.href;   
        console.log(newHref);
      }, { once: true });   
      
      el.addEventListener('load', function () {
        console.log('Load Event Fired',el.src);
        //var newHref = el.contentWindow.location.href;   
        console.log(' el Event Fired ',el.contentWindow.location.href);
        var newHref = el.src;
        console.log(newHref);

        if (newHref.toLowerCase().indexOf("mobilepaymentsuccess") > -1) {
          setTimeout(()=>{ 
            //this.checkorder = true;
            console.log("mobilepaymentsuccess");
            //AppComponent.cartTot = result.Result.BasketPrice;
          },3000);
        }else if(newHref.toLowerCase().indexOf("mobilepaymentfail") > -1){
          //this.process = false;
          console.log("mobilepaymentfail");
          setTimeout(()=>{ 
          // this.snackBar.open('Sipariş ödemeniz bankanız tarafından onaylanmamıştır.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 9000 });
          },3000);
        }
      }, { once: true });


    }, 3000);

    //var frameObj = nativeEl.contentDocument || nativeEl.contentWindow.document;
    //frameObj.postMessage("ddddd");

    


    // if ( (nativeEl.contentDocument || nativeEl.contentWindow.document).readyState === 'complete' )        {
    //     nativeEl.onload = this.onIframeLoad.bind(this);
    // } else {
    //   if (nativeEl.addEventListener) {
    //     nativeEl.addEventListener('load', this.onIframeLoad.bind(this), true);
    //   } else if (nativeEl.attachEvent) {
    //     nativeEl.attachEvent('onload', this.onIframeLoad.bind(this));
    //   }
    // }
  }

  onIframeLoad() {

    // var self = this;
    // setTimeout(() => {
    //   if(self.iframe.nativeElement.contentDocument){
    //     console.log("Content document");
    //   }else if(self.iframe.nativeElement.contentWindow){
    //     console.log("Content window");
    //     var currentWindow = self.iframe.nativeElement.contentWindow;
    //     console.log(currentWindow);
    //   }  
    // }, 1000);

    
    
    //const base64String = this.iframe.nativeElement.contentWindow.document.body.innerHTML;
    
    //console.log(this.iframe.nativeElement.contentWindow.document.body.children[1].currentSrc);
  }

  ngAfterContentInit() {
    
  }

onLoadFrame(currentFrame:any){
  //var document = currentFrame.contentDocument || currentFrame.contentWindow;
  //console.log(document);
}

  ngOnInit() {
     
   
     
  }

 

  public close(): void {
    //this.dialogRef.close(this.checkorder);
    this.dialogRef.close(true);
  }

  

}
