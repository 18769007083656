import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountProvider } from '../../shared/services/account';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppComponent } from "src/app/app.component";
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

export interface ResultInterface {
  Result: any;  
  HasError: string;  
  Error: string;
}
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.sass']
})
export class AddressComponent implements OnInit {
  addressList:any[]=[];
  frmAdrss : FormGroup;
  submitted: boolean = false;
  selectedCityId:number=0;
  addressId:number =0;
  cityList:any[]=[];
  error: any;
  loggedIn: any;
  logg: any;
  toggleId: boolean = false;
  CityId: number =0;
  constructor(
    private fb: FormBuilder,
    public router: Router,
    private accountProvider: AccountProvider, 
    private titleService: Title, public snackBar: MatSnackBar
    ) {  
      this.frmAdrss = this.fb.group({
        Name: ['', Validators.compose([Validators.maxLength(30), Validators.required])],
        Firstname: ['', Validators.compose([Validators.maxLength(12), Validators.required])],
        Lastname: ['', Validators.compose([Validators.maxLength(12), Validators.required])],
        Mobile: ['', Validators.compose([Validators.maxLength(12), Validators.required])],
        CityId: ['', Validators.compose([Validators.maxLength(12), Validators.required])],
        Address: ['', Validators.compose([Validators.maxLength(12), Validators.required])],
      });
      this.titleService.setTitle('Adreslerim - Depolaa');
  }
  selectCity(e){
    let selectedCityValue = this.frmAdrss.get('CityId').value;
    this.selectedCityId=selectedCityValue;   
    this.frmAdrss.controls['CityId'].patchValue(selectedCityValue);
    console.log(this.selectedCityId);
  }
  ngOnInit() {
    this.logg = this.accountProvider.getUserInfo();     
    this.loggedIn = this.logg ? 3 : 0;
    console.log(this.logg);
    if(this.logg){
      AppComponent.memberId = this.logg.DataId;
      AppComponent.memberIsStudent = this.logg.IsStudent;  
      AppComponent.memberIdq = this.logg.MemberId;
        console.log(this.logg);
        
    } else {
      this.router.navigate(['/uye-girisi']);
    }
    this.accountProvider.getAddressList(this.logg.MemberId).subscribe((profileResult: ResultInterface)=>{
      console.log(profileResult);
      if(profileResult.HasError){
       // this.utilityProvider.doAlert('Hata !',profileResult.Error,'OK').present();
      }else{
       this.addressList = profileResult.Result;
        

      }
    }); 

  }

  openEdit(address) {     
    this.accountProvider.cityList().subscribe( (result: ResultInterface)=>{
      if(result.Result){
        this.cityList = [];
        result.Result.forEach(element => {
         this.cityList.push({Id:element.Id,Name:element.Name}); 
        }); 
        if(address.Id>0){
          this.accountProvider.getAddressInfo(AppComponent.memberIdq,address.Id).subscribe( (addressResult: ResultInterface) =>{
            console.log("EDIT",addressResult.Result);
            this.frmAdrss.controls['Name'].patchValue(addressResult.Result.Name);
            this.frmAdrss.controls['Firstname'].patchValue(addressResult.Result.Firstname);
            this.frmAdrss.controls['Lastname'].patchValue(addressResult.Result.Lastname);
            this.frmAdrss.controls['Mobile'].patchValue(addressResult.Result.Mobile);
            this.frmAdrss.controls['CityId'].patchValue(addressResult.Result.CityId);
            this.selectedCityId=addressResult.Result.CityId;
            this.frmAdrss.controls['Address'].patchValue(addressResult.Result.Address);          
          });
        }else{
        
        }
      }
    });
  }

  toggled() {
    if(this.toggleId) {
      this.toggleId = false;
    } else {
      this.toggleId = true;
    }

    this.accountProvider.cityList().subscribe( (result: ResultInterface)=>{
      if(result.Result){
        this.cityList = [];
        result.Result.forEach(element => {
         this.cityList.push({Id:element.Id,Name:element.Name}); 
        });  
        
            this.frmAdrss.controls['Name'].patchValue("");
            this.frmAdrss.controls['Firstname'].patchValue("");
            this.frmAdrss.controls['Lastname'].patchValue("");
            this.frmAdrss.controls['Mobile'].patchValue("");
            this.frmAdrss.controls['CityId'].patchValue(0);
            this.selectedCityId=0;
            this.frmAdrss.controls['Address'].patchValue("");  
      }
    });
  }

  saveAddress(s){
    var addressData = this.frmAdrss.value; 
     
    let model = {
      Type : s ? 'Edit' : 'Save',
      Id : s ? s.Id : 0,
      MemberId : AppComponent.memberIdq,
      Name :addressData.Name,
      Firstname:addressData.Firstname,
      Lastname:addressData.Lastname,
      Mobile:addressData.Mobile,
      CountryId:1,
      CityId:addressData.CityId,
      Address:addressData.Address
    };
    this.accountProvider.saveAddress(model).subscribe( (result: ResultInterface) =>{
       
      if(result.HasError){        
         
      }else{
        this.addressList = result.Result;     
        this.toggleId = false;
        this.snackBar.open('Adresiniz ' + (s ? 'Güncellenmiştir' : 'Eklenmiştir'), '', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        this.ngOnInit();
      }
    }); 
  }

  delete(address){
     
    let model = {
      Type : 'Delete',
      Id : address.Id,
      MemberId :  AppComponent.memberIdq,
      Name :"",
      Firstname:"",
      Lastname:"",
      Mobile:"",
      CountryId:1,
      CityId:40,
      Address:""
    };
    this.accountProvider.saveAddress(model).subscribe((result: ResultInterface)=>{
      
      if(result.HasError){        
        
      }else{    
        this.addressList = result.Result;    
        this.snackBar.open('Adresiniz Silinmiştir', '', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        this.ngOnInit();
      }
    }); 

  }
}