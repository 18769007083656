import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ApiProvider } from "./api";

@Injectable({
  providedIn: 'root'
})
export class AccountProvider {
  constructor(public apiProvider: ApiProvider) {}

  setUserInfo(data) {
    console.log(data);
    localStorage.setItem("UserInfo", JSON.stringify(data));
    return JSON.parse(localStorage.getItem("UserInfo"));
  }

  getUserInfo() { 
    return JSON.parse(localStorage.getItem("UserInfo"));
  }

  setSearchHistory(dataSource) {
    return localStorage.setItem("SearchHistory", dataSource);
  }

  getSearchHistory() {
    return parseInt(localStorage.getItem("SearchHistory"));
  }

  clearSearchHistory() {
    return localStorage.setItem("SearchHistory", null);
  }

  logout() {
    return localStorage.setItem("UserInfo", null);
  }

  login(registerData: any) {
    var model = {
      Email: registerData.email,
      Password: registerData.password,
    };
    return this.apiProvider.postPromise("/Account/Login", model);
  }

  forgetPassword(formData: any) {
    var model = {
      Email: formData.email,
    };
    return this.apiProvider.postPromise("/Account/ForgetPassword", model);
  }

  cityList() {
    return this.apiProvider.postPromise("/Common/CityList", null);
  }

  register(registerData: any) {
    var model = {
      IsStudent: registerData.isStudent,
      Firstname: registerData.firstname,
      Lastname: registerData.lastname,
      Username: registerData.username,
      Email: registerData.email,
      Mobile: registerData.mobile,
      Password: registerData.password,
      TaxNumber: registerData.taxNumber,
      TaxOffice: registerData.taxOffice,
      OfficeName: registerData.officeName,
      OfficeAddress: registerData.officeAddress,
      OfficePhone: registerData.officePhone,
      University: registerData.university,
      ExpertCategory: registerData.expertCategory,
      Platform: registerData.platform,
      DeviceToken: registerData.deviceToken,
      CityId: registerData.cityId,
      RefNumber: registerData.refNumber,
    };
    return this.apiProvider.postPromise("/Account/Register", model);
  }

  getProfileInfo(memberId) {
    var model = {
      MemberId: memberId,
    };
    return this.apiProvider.postPromise("/Member/ProfileInfo", model);
  }

  updateProfile(profileData: any) {
    return this.apiProvider.postPromise("/Member/ProfileUpdate", profileData);
  }

  updateWork(workData: any) {
    return this.apiProvider.postPromise("/Member/WorkUpdate", workData);
  }

  updateAccount(accountData: any) {
    return this.apiProvider.postPromise("/Member/AccountUpdate", accountData);
  }

  updateProfilePhoto(memberId, photoUrl) {
    var model = {
      MemberId: memberId,
      Photo: photoUrl,
    };
    return this.apiProvider.postPromise("/Member/ProfilePhotoUpdate", model);
  }

  saveDevice(memberId, platform, signalId, deviceToken) {
    var model = {
      MemberId: memberId,
      Platform: platform,
      DeviceToken: deviceToken,
      OneSignalId: signalId,
    };
    return this.apiProvider.postPromise("/Member/SaveDevice", model);
  }

  status(status, type, userId) {
    let URL = "/Member/Status";
    var model = {
      ClientId: userId,
      Status: status,
      Type: type,
    };
    return this.apiProvider.postPromise(URL, model);
  }

  accountIsValid(uniqueId) {
    return this.apiProvider.postPromise(
      "/Account/AccountIsValid?uniqueId=" + uniqueId,
      null
    );
  }

  search(searchText, memberId) {
    return this.apiProvider.postPromise(
      "/Temp/Search?search=" + searchText + "&memberId=" + memberId,
      null
    );
  }

  getAddressList(memberId) {
    return this.apiProvider.postPromise(
      "/Member/AddressList?memberId=" + memberId,
      null
    );
  }

  saveAddress(model) {
    return this.apiProvider.postPromise("/Member/AddressOperation", model);
  }

  getAddressInfo(memberId, addressId) {
    var model = {
      Id: addressId,
      MemberId: memberId,
    };
    return this.apiProvider.postPromise("/Member/AddressInfo", model);
  }

  getNotificationList(memberId,page) {
    var model = {
      MemberId: memberId,
      Page:page
    };
    return this.apiProvider.postPromise("/Member/NotificationList", model);
  }

  setNotificationAction(memberId, notificationId, action) {
    var model = {
      MemberId: memberId,
      NotificationId: notificationId,
      Action: action,
    };
    return this.apiProvider.postPromise("/Member/NotificationAction", model);
  }

  sendContactForm(nameSurname, phone, email, message, platform) {
    var model = {
      NameSurname: nameSurname,
      Phone: phone,
      Email: email,
      Message: message,
      Platform: platform,
    };
    return this.apiProvider.postPromise("/Common/SendContactForm", model);
  }
  
  getVideoList() {
    return this.apiProvider.postPromise("/Temp/VideoList", {});
  }

}
