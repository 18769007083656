import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CartItem } from 'src/app/modals/cart-item';
import { CartService } from '../../shared/services/cart.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Title } from '@angular/platform-browser';
import { AppComponent } from "src/app/app.component";
export interface PageInterface {
  HasError: string; 
  Result: any; 
}

export interface CartInterface {
  BankList: any;
  BasketId: string;
  BasketPrice: number;
  CreditAmount: number;
  Detail: any;
  HasCredit: boolean;
  MemberId: number;
  MemberPoint: number;
  NetPrice: number;
  TaxPrice: number; 
}

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.sass']
})
export class CartComponent implements OnInit {

  public cartItems : Observable<CartItem[]> = of([]);
  public shoppingCartItems : CartInterface;

  constructor(private cartService: CartService, private titleService: Title, private spinner: NgxSpinnerService) { 
    this.spinner.show();
    this.titleService.setTitle('Sepet - Depolaa');
    this.initialize();
  }

  ngOnInit() {
    // this.cartItems = this.cartService.getItems();
    // this.cartItems.subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    this.initialize();
  }

  public initialize() {
    this.cartService.getMemberBasket().subscribe((item: PageInterface) => {
      if(item.HasError) {
        this.shoppingCartItems = {
          BankList: [],
          BasketId: null,
          BasketPrice: null,
          CreditAmount: null,
          Detail: [],
          HasCredit: null,
          MemberId: null,
          MemberPoint: null,
          NetPrice: null,
          TaxPrice: null
        };
        this.spinner.hide();
      } else {

        // BankList: [{…}]
        // BasketId: "466e6e98-6474-4406-8db7-5d81d690e813"
        // BasketPrice: 749.2190804
        // CreditAmount: 0
        // HasCredit: false
        // MemberId: 1
        // MemberPoint: 0
        // NetPrice: 666.71678
        // TaxPrice: 82.5023004 

        this.shoppingCartItems = item.Result;
        //         AttrId: "1"
        // AttrName: "Renk"
        // AttrValue: "A2"
        // Discount: "37,51 ₺"
        // FinalPrice: "81,02 ₺"
        // ListPrice: "112,53 ₺"
        // Logo: "http://panel.depolaa.com/Media/Product/40cd7b97-dd55-4d9a-89c7-19737486b4e7.jpg?width=750"
        // Name: "Vericom Denfil Nano Kompozit"
        // NetPrice: "75,02 ₺"
        // Price: "81,02 ₺"
        // ProductId: 1536
        // Quantity: 1
        // TaxPrice: "6,00 ₺"
        // TaxRate: "% 8 KDV"
      }
      console.log(item);
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinner.hide();
      }, 1000);
    });
  }

  updateBasket(productIndex : number,type : string,newQuantity?:number){ 
    console.log(this.shoppingCartItems);
    var product = this.shoppingCartItems.Detail[productIndex];     
    this.cartService.setBasketOption(product.ProductId,type,product.AttrId,product.AttrValue,newQuantity).subscribe((result: PageInterface)=>{
      this.shoppingCartItems = result.Result; 
      AppComponent.cartTot = result.Result.BasketPrice;
   });    
  }
    
   // Increase Product Quantity
   public increment(product: any, quantity: number = 1) {
    
  }

  // Decrease Product Quantity
  public decrement(product: any, quantity: number = -1) {
    
  } 

}
