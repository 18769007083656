import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscriber } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Product } from 'src/app/modals/product.model';
import { MatSnackBar } from '@angular/material';
import { map } from 'rxjs/operators';
import { ApiProvider } from '../../shared/services/api';
import { Response } from 'src/app/modals/response';
import { SlideModel } from 'src/app/modals/slides';
import { AppComponent } from "src/app/app.component";

// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("compareItem")) || [];

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public currency : string = 'TL';
  public catalogMode : boolean = false;
  BASE_URL: string;

   
  private _url: string = "assets/data/";
  public url = "assets/data/banners.json";

  public compareProducts : BehaviorSubject<Product[]> = new BehaviorSubject([]);
  public observer   :  Subscriber<{}>;

  constructor(private httpClient: HttpClient, public apiProvider:ApiProvider, public snackBar: MatSnackBar) {
    this.BASE_URL = "http://apiv2.depolaa.com/api";
    this.compareProducts.subscribe(products => products = products);
  }
  
  public getProductListAllType(){ 
    let model = {
      MemberId: AppComponent.memberId,
    };
    console.log("memberId",AppComponent.memberId);
    return <Observable<Response<SlideModel>>>this.apiProvider.postPromise("/ListV2/ProductListAllType", model);
  }
  
  public getSlides(isCategory,categoryId):Observable<Response<SlideModel>>{
    return <Observable<Response<SlideModel>>>this.apiProvider.postPromise('/Temp/Slider?isWeb=true&isCategory='+isCategory+'&categoryId='+categoryId, {});
  }

  public getProductDetail(productId) {
    var model = {
      ProductId: productId,
      MemberId: AppComponent.memberId,
    };
    return this.apiProvider.postPromise("/ProductV2/ProductDetail", model);
  }
  getProductListBySimilar(productId) {
    var model = {
      ProductId: productId,
      MemberId: AppComponent.memberId,
    };
    return this.apiProvider.postPromise("/ProductV2/ProductsBySimilar", model);
  }
  
  // Get Products By Id
  // public getProduct(id: number): Observable<Product> {
  //   return this.products().pipe(map(items => {
  //     return items.find((item: Product) =>
  //       { return item.Id === id; });
  //     })); 
  // }

  // If item is aleready added In compare
  public hasProduct(product: Product): boolean {
    const item = products.find(item => item.id === product.Id);
    return item !== undefined;
  }

  // Get Products By category
  public getProductByCategory(category: string,sub: string) {
  var model = {
    MemberId: AppComponent.memberId,
    MainCategoryId: category,
    CategoryId: sub,
  };
  return <Observable<Response<Product[]>>>this.apiProvider.postPromise("/ProductV2/Products", model);
  }

  public getProductListByBrand(brandId: number) {
    var model = {
      MemberId: AppComponent.memberId,
      BrandId: brandId,
    }; 
    return <Observable<Response<Product[]>>>this.apiProvider.postPromise("/ProductV2/ProductsByBrand", model);
  }

  sendProductDemand(memberId, productId, message) {
    var model = {
      MemberId: AppComponent.memberId,
      ProductId: productId,
      Message: message,
    };
    return this.apiProvider.postPromise("/Common/SendProductRequest", model);
  }

}
