import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { AccountProvider } from './components/shared/services/account';
import { Title, Meta } from '@angular/platform-browser';
import {CartService} from "./components/shared/services/cart.service";
import { Router, NavigationEnd } from '@angular/router';
import { SidebarMenuService } from './components/shared/sidebar/sidebar-menu.service';
import { SidenavMenu } from './components/shared/sidebar/sidebar-menu.model';
import { MenuService } from './components/shared/services/menu.service';
import {Product} from "./modals/product.model";
import {CartItem} from "./modals/cart-item";

export interface ResultInterface {
  Result: any;  
  HasError: any;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public static member: string = "";
  public static memberIdq: string = "";
  public static memberId: number = 0;
  public static memberIsStudent: boolean = false;
  public static memberIsCredit: boolean = false;
  public static points: string = "0.00";
  public static profilePhotoUrl: any;
  public static cartTot: number = 0;
  title = 'Depolaa';
  public sidenavMenuItems:Array<any>;
  public static menus: any;
  currentMenu:any;
  public url : any;
  navItems: SidenavMenu[] = [];
  constructor(
    public accountProvider: AccountProvider, 
    private spinner: NgxSpinnerService,
    private metaTagService: Meta,
    private titleService: Title,
    public menuService: MenuService, 
    public router: Router,
    public sidenavMenuService:SidebarMenuService
    ) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.url = event.url;
        }
      } )
    }


  ngOnInit() {
    /** spinner starts on init */
    //this.spinner.show();
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'keywords', content: 'depolaa, dentist alışveriş, diş hekimleri, öğrenciler, perakende' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Depolaa' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2021-3-31', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);

    // setTimeout(() => {
    //   /** spinner ends after 5 seconds */
    //   this.spinner.hide();
    // }, 5000);

    const user = this.accountProvider.getUserInfo();

    if (user) {

      this.accountProvider.getProfileInfo(user.MemberId).subscribe((profileResult: ResultInterface)=>{
        
        if(profileResult.HasError){
           
        }else{
          AppComponent.points = profileResult.Result.Point;
          ///console.log("profileResult",profileResult.Result);
          AppComponent.profilePhotoUrl = profileResult.Result.ProfilePhoto;    
          AppComponent.memberIsCredit = profileResult.Result.HasCredit;   
        }
      });
      AppComponent.memberId = user.DataId;
      AppComponent.memberIdq = user.MemberId;
      AppComponent.memberIsStudent = user.IsStudent;     
      AppComponent.member = user.Firstname + ' ' + user.Lastname;
    }

    this.menuService.categoryList.subscribe((res)=>{
      AppComponent.menus = res.Result
      this.currentMenu = res.Result;
    })

    window.scroll(0,0);
  }
  
  onActivate(event) {
    document.getElementsByClassName('mat-drawer-content')[0].scrollTo(0, 0);
  }

}
