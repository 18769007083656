import { filter, map } from 'rxjs/operators';
import { Injectable, NgModule } from '@angular/core';
import { Routes,Router, RouterModule, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationStart } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProductDetailsComponent } from './products/product-details/product-details.component';
import { ProductLeftSidebarComponent } from './products/product-left-sidebar/product-left-sidebar.component';


@Injectable()
export class CategoryUrlGuard implements CanActivate {

  constructor(public router: Router){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      console.clear();
      var dataState = this.router.getCurrentNavigation().extras.state;
      console.log(dataState);
      if(dataState && dataState.type){
        console.log(dataState.type);
        return dataState.type =='mainCategory' || dataState.type=='subCategory';
      }
      return false;
      
  }
}

const routes: Routes = [
  {
    path: '',
    //component: MainComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'marka/:brand', component: ProductLeftSidebarComponent },
      { path: 'liste/:list', component: ProductLeftSidebarComponent },
      { path: ':category', component: ProductLeftSidebarComponent},
      { path: ':maincategory/:category', component: ProductLeftSidebarComponent},
      { path: ':maincategory/:category/:product', component: ProductDetailsComponent },
      
      // { 
      //   path: '**', 
      //   component: ProductLeftSidebarComponent, 
      //   canActivate:[CategoryUrlGuard]
      // },
    ]
  }
]; 

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShopRoutingModule { }
