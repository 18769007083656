import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../shared/services/menu.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {
  public menus: any;
  constructor(public menuService: MenuService) { }

  ngOnInit() {
    this.menuService.categoryList.subscribe((res)=>{
      this.menus = res.Result
    })
  }

}
