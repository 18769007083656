import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Product } from 'src/app/modals/product.model';
import { MenuService } from 'src/app/components/shared/services/menu.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Title, Meta } from '@angular/platform-browser';
import { AppComponent } from "src/app/app.component";

export interface BrandInterface {
  BrandName: string;  
}
 

@Component({
  selector: 'app-product-left-sidebar',
  templateUrl: './product-left-sidebar.component.html',
  styleUrls: ['./product-left-sidebar.component.sass'],
  //animations: [ <yourAnimationMethod()> ]

  //animations: [Animation]
})
export class ProductLeftSidebarComponent implements OnInit {
  public sidenavOpen:boolean = true;
  public animation    :   any;   // Animation
  public sortByOrder  :   string = '';   // sorting
  public page:any;
  public tagsFilters  :   any[] = [];
  public viewType: string = 'grid';
  public viewCol: number = 25;
  //public colorFilters :   ColorFilter[] = [];

  public items        :   Product[] = [];
  public allItems: Product[] = [];
  public products: Product[] = [];
  public tags         :   any[] = [];
  public colors       :   any[] = [];
  public categories       :   any[] = [];
  public brands       :   any[] = [];
  public catId : number;
  public mcatId : number;
  public brandId : number;
  public listId: number;
  public menus: any;

  constructor(public menuService: MenuService, private spinner: NgxSpinnerService, private productService: ProductService, 
    private route: ActivatedRoute,
    private router:Router,
    private metaTagService: Meta,
    private titleService: Title
    ) {
      console.clear();
      this.route.params.subscribe(
        (params: Params) => {
          const category = params['category'];
          const mainCategory = params['maincategory'];
          const brand = params['brand'];
          const list = params['list'];
          this.catId = 0;
          this.mcatId = 0;
          this.menuService.categoryList.subscribe((result:any) => {
            if(list) {
              this.listId = list;
            } else if(brand) {
              var bsplit = brand.split("-",10);
              this.brandId = parseInt(bsplit[bsplit.length-1]);
            } else {
              if(mainCategory){
                result.Result.forEach(element => {
                  if(element.Url.indexOf(mainCategory)!==-1){
                    var searchResult = element.SubCategories.filter(x=>x.Url.indexOf(category)!==-1);
                    if(searchResult.length>0){
                      this.catId = searchResult[0].Id;
                      this.mcatId = searchResult[0].MainCategoryId;
                    }
                  }
                  
                });
              }else{              
                var searchResult = result.Result.filter(x=>x.Url == category);
                if(searchResult.length>0){
                  this.catId = searchResult[0].Id;
                }
              }
            }

          });
        }
      ) 
      
      this.router.onSameUrlNavigation = 'reload';   
  }

  ngOnInit() {    
    console.clear();
    this.route.params.subscribe(val=>{       
      console.log(val);
      const category = val['category'];
      const mainCategory = val['maincategory'];
      const brand = val['brand'];
      const list = val['list'];
      this.catId = 0;
      this.mcatId = 0;
      if(list) {
        this.bindList(list);
      }
      else if(brand) {
        this.bindBrandData();
      } else {      
        this.menuService.categoryList.subscribe((result:any) => {
          if(mainCategory){
            result.Result.forEach(element => {
              if(element.Url.indexOf(mainCategory)!==-1){
                var searchResult = element.SubCategories.filter(x=>x.Url.indexOf(category)!==-1);
                if(searchResult.length>0){
                  this.catId = searchResult[0].Id;
                  this.mcatId = searchResult[0].MainCategoryId;
                }
              }
              
            });
          }else{              
            var searchResult = result.Result.filter(x=>x.Url == category);
            if(searchResult.length>0){
              this.catId = searchResult[0].Id;
            }
          }
          this.bindData();
        });     
      } 
    });
  }
  ngOnDestroy(){
    //this.router.onSameUrlNavigation = 'ignore';
   }

  bindData(){
    this.spinner.show();
    console.log(this.mcatId.toString(),this.catId.toString());
    
    this.productService.getProductByCategory(this.mcatId.toString(),this.catId.toString()).subscribe(products => {
      this.spinner.hide();
      console.log(products);
      this.allItems = products.Result;
      this.products = products.Result.slice(0.8);
      //this.getTags(products);
      //this.getColors(products);
      this.getBrands(products.Result);
      this.getCategories();      
      if(this.mcatId>0) {
        if(products.Result[0]) {
          this.titleService.setTitle(products.Result[0].Category + ' ' + products.Result[0].MainCategory + ' | Depolaa');
        }
      } else {
        this.titleService.setTitle( products.Result[0].MainCategory + ' | Depolaa');  
      }
      
      this.metaTagService.addTags([
        { name: 'keywords', content: 'depolaa, dentist alışveriş, diş hekimleri, öğrenciler, perakende' },
        { name: 'robots', content: 'index, follow' },
        { name: 'author', content: 'Depolaa' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'date', content: '2021-3-31', scheme: 'YYYY-MM-DD' },
        { charset: 'UTF-8' }
      ]);
  });
  }

  bindBrandData(){
    this.spinner.show();    
    this.productService.getProductListByBrand(this.brandId).subscribe(products => {
      this.spinner.hide();
      console.log(products);

      this.allItems = products.Result;
      this.products = products.Result.slice(0.8);
      //this.getTags(products);
      //this.getColors(products);
      this.getBrands(products.Result);
      this.getCategories();      
      if(this.mcatId>0) {
        if(products.Result[0]) {
          this.titleService.setTitle(products.Result[0].Category + ' ' + products.Result[0].MainCategory + ' | Depolaa');
        }
      } else {
        this.titleService.setTitle( products.Result[0].MainCategory + ' | Depolaa');  
      }
      
      this.metaTagService.addTags([
        { name: 'keywords', content: 'depolaa, dentist alışveriş, diş hekimleri, öğrenciler, perakende' },
        { name: 'robots', content: 'index, follow' },
        { name: 'author', content: 'Depolaa' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'date', content: '2021-3-31', scheme: 'YYYY-MM-DD' },
        { charset: 'UTF-8' }
      ]);
  });
  }

  bindList(e) {
    this.spinner.show();    
    this.productService.getProductListAllType().subscribe((res: any) => {
      this.spinner.hide(); 

      this.allItems = res.Result[e].List;
      this.products = res.Result[e].List.slice(0.8);
      //this.getTags(products);
      //this.getColors(products);
      this.getBrands(res.Result[e].List);
      this.getCategories();      
        this.titleService.setTitle( res.Result[e].Name + ' | Depolaa');  

      this.metaTagService.addTags([
        { name: 'keywords', content: 'depolaa, dentist alışveriş, diş hekimleri, öğrenciler, perakende' },
        { name: 'robots', content: 'index, follow' },
        { name: 'author', content: 'Depolaa' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'date', content: '2021-3-31', scheme: 'YYYY-MM-DD' },
        { charset: 'UTF-8' }
      ]);

 
    });
  }
  
  public getCategories() {
    var uniqueCats = []
    var itemCats = Array();
    
    this.menuService.categoryList
    .subscribe(result => {
      if(result.hasError) {} else {        
        console.log(result);
        this.menus = result.Result;
        this.menus.map((product, index) => {
         if(product) { 
          console.log(product.MainCategoryId,this.catId);
          if(this.mcatId) {
            if(product.Id == this.mcatId) 
            {
              product.SubCategories.map((sub, index) => {
                uniqueCats.push(sub);
              });
            }
          } else {
            if(product.Id == this.catId) 
            {
              product.SubCategories.map((sub, index) => {
                uniqueCats.push(sub);
              });
            }
          }
          
         }
       });
      //  for (var i = 0; i < uniqueCats.length; i++) {
      //   itemCats.push({brand:uniqueCats[i]})
      //  }
       this.categories = uniqueCats;
       console.log(this.categories);
      }       
    });    
     
  }
  
     public getBrands(products) {
      var uniqueBrands = []
      var itemBrand = Array();
      products.map((product, index) => {
         if(product.BrandName) { 
            const index = uniqueBrands.indexOf(product.BrandName);
            if(index === -1)  uniqueBrands.push(product.BrandName); 
        }
      });
      for (var i = 0; i < uniqueBrands.length; i++) {
        itemBrand.push({brand:uniqueBrands[i]})
      }
      this.brands = itemBrand;
      console.log(this.brands);
   }

     // Get current product tags
     public getTags(products) {
      var uniqueBrands = []
      var itemBrand = Array();
      products.map((product, index) => {
         if(product.tags) {
            product.tags.map((tag) => {
            const index = uniqueBrands.indexOf(tag);
            if(index === -1)  uniqueBrands.push(tag);
         })
        }
      });
      for (var i = 0; i < uniqueBrands.length; i++) {
           itemBrand.push({brand:uniqueBrands[i]})
      }
      this.tags = itemBrand
   }

     // Get current product colors
     public getColors(products) {
      var uniqueColors = []
      var itemColor = Array();
      products.map((product, index) => {
        if(product.colors) {
        product.colors.map((color) => {
            const index = uniqueColors.indexOf(color);
            if(index === -1)  uniqueColors.push(color);
        })
       }
      });
      for (var i = 0; i < uniqueColors.length; i++) {
           itemColor.push({color:uniqueColors[i]})
      }
      this.colors = itemColor
   }

 



  public changeViewType(viewType, viewCol){
    this.viewType = viewType;
    this.viewCol = viewCol;
  }
    // Animation Effect fadeIn
    public fadeIn() {
      this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
      this.animation = 'fadeOut';
  }

    // Update tags filter
    public updateTagFilters(tags: any[]) {
      this.tagsFilters = tags;
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }



    // sorting type ASC / DESC / A-Z / Z-A etc.
    public onChangeSorting(val) {
      this.sortByOrder = val;
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
   }

     // Initialize filetr Items
//   public filterItems(): Product[] {
//     return this.items.filter((item: Product) => {
//         const Colors: boolean = this.colorFilters.reduce((prev, curr) => { // Match Color
//           if(item.colors){
//             if (item.colors.includes(curr.color)) {
//               return prev && true;
//             }
//           }
//         }, true);
//         const Tags: boolean = this.tagsFilters.reduce((prev, curr) => { // Match Tags
//           if(item.tags) {
//             if (item.tags.includes(curr)) {
//               return prev && true;
//             }
//           }
//         }, true);
//         return Colors && Tags; // return true
//     });
// }

public onPageChanged(event){
  this.page = event;
  this.allItems;
  window.scrollTo(0,0);
}


  // Update price filter
//   public updatePriceFilters(price: any) {
//     let items: any[] = [];
//     this.products.filter((item: Product) => {
//         if (item.price >= price[0] && item.price <= price[1] )  {
//            items.push(item); // push in array
//         }
//     });
//     this.items = items;
// }


  // Update price filter
  public updatePriceFilters(price: any) {
    console.log(price);
    console.log(this.products);


   this.allItems = this.products.filter((item: Product) => {
    console.log(parseFloat(item.Price));
     return parseFloat(item.Price) >= price.priceFrom && parseFloat(item.Price)  <= price.priceTo
    });
     
     console.log(this.products);

}

onBrendsChanged(newBrend) {
  console.log(newBrend);
  this.allItems = newBrend === 'all' ? this.products : this.products.filter( 
    item => item.BrandName === newBrend
  )
  console.log(this.allItems);
}
}
