import 'hammerjs';
import { BrowserModule, HAMMER_GESTURE_CONFIG  } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { iframeResizer } from 'iframe-resizer';
import { AppComponent } from './app.component'; 
import { registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
 
registerLocaleData(localeTr, 'tr');

import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { 
  GestureConfig,
  MatToolbarModule, 
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule ,
  MatStepperModule,
  MatInputModule,
  MatFormFieldModule,
  MatSliderModule
} from '@angular/material';
 


import { FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxImgZoomModule } from 'ngx-img-zoom';
 

import { MainComponent } from './components/main/main.component';

import {LocationStrategy, PathLocationStrategy} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';

import { PagesModule } from './components/pages/pages.module';
import { ShopModule } from './components/shop/shop.module';
import { SharedModule } from './components/shared/shared.module';
import { IframeResizerDirective } from './iframe-resizer.directive';
import { IframeEventDirective } from './iframe-event.directive';
import { RouterModule } from '@angular/router';


declare var Hammer: any;
//@Injectable()
export class HammerConfig extends GestureConfig  {
  buildHammer(element: HTMLElement) {
    return new GestureConfig({touchAction: 'pan-y'}).buildHammer(element);
  }
}

@NgModule({
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule, 
    MatSliderModule,
 ],
  exports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule, 
    MatSliderModule,  
  ],
  declarations: [  ],
})export class MaterialModule {};
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    IframeResizerDirective, 
    IframeEventDirective,
  ],
  imports: [
    BrowserAnimationsModule,
    NoopAnimationsModule,
    NgxSpinnerModule,
    BrowserModule,
    SharedModule,
    PagesModule,
    ShopModule,
    HttpClientModule,
    MatSliderModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxImgZoomModule, 
    RouterModule,
    
  ],
  exports: [ 
    IframeResizerDirective,
    IframeEventDirective
  ],
  providers: [
    // {
    //   provide: LOCALE_ID,
    //   useValue: 'tr' // 'de' for Germany, 'fr' for France ...
    //  },
   
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

