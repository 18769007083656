import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscriber } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators'; 
 

@Injectable({
    providedIn: 'root'
  })
export class ApiProvider {

  BASE_URL: string;
  
  constructor(public http: HttpClient ) {
    this.BASE_URL = "https://apiv2.depolaa.com/api/";
    //this.BASE_URL = "http://192.168.1.33:846/api";
    
  }

  postPromise(url: string, data: any) {
    url = this.BASE_URL + url;    
    return this.post(url, data);
  }

  post(url: string, data: any) {
    let headers2 = new HttpHeaders();
    headers2.append('Content-Type', 'application/json');
    headers2.append('Accept', 'application/json');
    headers2.append('Accept-Encoding','gzip');
    console.log("Api Info => " + url);
    console.log("Api Post Data => " + JSON.stringify(data));
    //let options = new RequestOptions({ headers: headers });
    let options = {
        headers: headers2
      };
      // return this.http.post<any[]>(url, data, options).pipe(
      //   map( response => {
      //       console.log(response);
      //       return response 
      //  } )
      // );  
      return this.http.post(url, data, options);  
  }
 

}
