import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { WishlistService } from 'src/app/components/shared/services/wishlist.service';
import { Product } from 'src/app/modals/product.model';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.sass']
})
export class ProductComponent implements OnInit {

  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Input() product: Product;  
  constructor(public productsService: ProductService, 
    private wishlistService: WishlistService) { 
       
    }

  ngOnInit() {
  }

  getProductUrl(){
    var productUrl : string[]=[];
    // productUrl.push('/');
    this.product.Url.split('/').forEach(element => { 
      productUrl.push(element);
    });
    return productUrl;
  }

     // Add to cart
     public addToCart(product: Product,  quantity: number = 1) {
      // this.cartService.addToCart(product,quantity);
      // console.log(product, quantity);
    }

    // Add to wishlist
    public addToWishlist(product: Product) {
      if(product.IsFavorited) {
        this.wishlistService.removeFromWishlist(product).subscribe(item => {   
          console.log(item);
          product.IsFavorited = false;
        }); 
      } else {
        this.wishlistService.addToWishlist(product).subscribe(item => {   
          console.log(item);
          product.IsFavorited = true;
        }); 
      }    
   } 


  public openProductDialog(product){
  }

}
