import {AfterViewInit, Directive, ElementRef, OnDestroy, Input,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';
// import { iframeResizer, IFrameComponent } from 'iframe-resizer';

@Directive({
  selector: '[appIframeResizer]'
})
export class IframeResizerDirective implements AfterViewInit  {
  //component: IFrameComponent;

  @Output() iframeClick = new EventEmitter<ElementRef>();
  constructor(public element: ElementRef) {

    element.nativeElement.style.backgroundColor = 'yellow';

  }
  // ngOnInit(): void {
  //   console.log("IFRAME IFRAME IFRAME");
  // }


  ngAfterViewInit() {
      // const components = iframeResizer({
      //     checkOrigin: false,
      //     heightCalculationMethod: 'documentElementOffset',
      //     log: false
      // }, this.element.nativeElement);
      console.log("IFRAME IFRAME IFRAME");
      let el = this.element.nativeElement;
     
      var iFrameHeight = this.element[0].contentWindow.document.body.scrollHeight + 'px';
      var iFrameWidth = '100%';
      
      el.setAttribute('style', 'width: ' + iFrameWidth + '; height: '+iFrameHeight+'');
      // /* save component reference so we can close it later */
      // this.component = components && components.length > 0 ? components[0] : null;
  }
  ngOnDestroy(): void {
      // if (this.component && this.component.iFrameResizer) {
      //     this.component.iFrameResizer.close();
      // }
  } 

  @HostListener('loadstart')
  private onIframeLoad() {
    console.log('Iframe mouse out');  
    this.iframeClick.emit(this.element);
  }
}



// import {AfterViewInit, Directive, ElementRef, OnDestroy} from '@angular/core';
// import { iframeResizer, IFrameComponent } from 'iframe-resizer';

// @Directive({
//   selector: '[appIframeResizer]'
// })
// export class IframeResizerDirective {
//   component: IFrameComponent;

//   constructor(public element: ElementRef) {
//   }

//   ngAfterViewInit() {
//       const components = iframeResizer({
//           checkOrigin: false,
//           heightCalculationMethod: 'documentElementOffset',
//           log: false
//       }, this.element.nativeElement);

//       /* save component reference so we can close it later */
//       this.component = components && components.length > 0 ? components[0] : null;
//   }

//   ngOnDestroy(): void {
//       if (this.component && this.component.iFrameResizer) {
//           this.component.iFrameResizer.close();
//       }
//   }

// }