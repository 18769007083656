import { Injectable } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CartItem } from 'src/app/modals/cart-item';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subscriber } from 'rxjs';
import { AccountProvider } from './account';
import { ApiProvider } from '../../shared/services/api';
import { AppComponent } from "src/app/app.component";
// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("cartItem")) || [];

@Injectable({
  providedIn: 'root'
})
export class CartService {

// Array
public cartItems  :  BehaviorSubject<CartItem[]> = new BehaviorSubject([]);
public observer   :  Subscriber<{}>;

  constructor(public accountProvider: AccountProvider,  public apiProvider:ApiProvider, public snackBar: MatSnackBar) {
    this.cartItems.subscribe(
      products => products = products
    );
   }

    // Get Products
  public getItems(): Observable<CartItem[]> {
    const itemsStream = new Observable(observer => {
      observer.next(products);
      observer.complete();
    });
    return <Observable<CartItem[]>>itemsStream;
  }

   // Add to cart
   public addToCart(productId,type,attributeId,attributeValue,newQuantity?:number) {
      let message, status;
      var item: CartItem | boolean = false;  
      let model = {
        MemberId:AppComponent.memberId, //AppComponent.memberIdq,
        ProductId:productId,
        Type:type,
        AttributeId:attributeId,
        AttributeValue:attributeValue,
        Quantity:newQuantity ? newQuantity : 0
      };
      return this.apiProvider.postPromise('/BasketV2/BasketOption', model);
    //  if(!this.accountProvider.getUserInfo()) { 
    //   this.snackBar.open('Lütfen üye girişi yapınız', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
    //     return [];
    //   } else {
    //     return this.apiProvider.postPromise('/BasketV2/BasketOption', model); 
    //   }
   }

   getMemberBasket() { 
      var model = {
          MemberId: AppComponent.memberId
      };  
    return this.apiProvider.postPromise('/BasketV2/MemberBasket', model);
  }
  setBasketOption(productId,type,attributeId,attributeValue,newQuantity?:number){
    let model = {
      MemberId:AppComponent.memberId,
      ProductId:productId,
      Type:type,
      AttributeId:attributeId,
      AttributeValue:attributeValue,
      Quantity:newQuantity ? newQuantity : 0
    };
    return this.apiProvider.postPromise('/BasketV2/BasketOption', model);
  }
  
}
