import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../shared/services/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass']
})
export class MenuComponent implements OnInit {
  public menus: any;


  constructor(public menuService: MenuService) { }

  ngOnInit() { 
    this.menuService.categoryList.subscribe((res)=>{
      this.menus = res.Result
    })
  }
  openMegaMenu(){
    // let pane = document.getElementsByClassName('cdk-overlay-pane');
    // [].forEach.call(pane, function (el) {
    //     if(el.children.length > 0){
    //       if(el.children[0].classList.contains('mega-menu')){
    //         el.classList.add('mega-menu-pane');
    //       }
    //     }
    // });
  }
  decodeUri(url){
    console.log(url);
    return decodeURIComponent(url);
  }
}
