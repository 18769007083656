import { Component, OnInit, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { Product } from 'src/app/modals/product.model';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class DialogComponent implements OnInit {
  public url; 
  public items; 
  @ViewChild('zoomImage', { static: true }) zoomImage;

  constructor( 
    private productsService: ProductService, 
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {url, name, address, cart}) {
      this.url = data.url; 
      this.items = data;
      // url: e,
      // name: AppComponent.member,
      // address: this.iframeAddress,
      // cart: this.basketData,
      console.log(data);
      }

  ngOnInit() {

  }

  public close(): void {
    this.dialogRef.close();
  }


}
