import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountProvider } from '../../shared/services/account';
import { AppComponent } from "src/app/app.component";
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass']
})
export class ContactComponent implements OnInit {
  frmContact : FormGroup;
  submitted : boolean = false;
  submitSuccess:boolean=false;
  error: any;
  
  constructor(private fb: FormBuilder,
    public router: Router,
    private accountProvider: AccountProvider, 
    public snackBar: MatSnackBar,
    private titleService: Title) {
      this.titleService.setTitle('İletişim - Depolaa');
        this.frmContact = this.fb.group({
          nameSurname: ['', [Validators.required]],
          phone: ['', [Validators.required]],
          email: ['', [Validators.required]],
          message: ['', [Validators.required]]
        });
     }

  ngOnInit() {
  }
  onSend() {
    let { nameSurname, phone, email, message } = this.frmContact.value;
    this.submitted = true;
   
    this.accountProvider.sendContactForm(nameSurname,phone,email,message,"web").subscribe(result=>{ 
      this.submitSuccess=true;
    });
  }
}
