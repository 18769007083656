import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { MainComponent } from './components/main/main.component';
// import { HomeComponent } from './components/shop/home/home.component';

const appRoutes: Routes = [
  {
    path: '', 
    loadChildren: () => import('./components/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '',
    loadChildren: () => import('./components/shop/shop.module').then(m => m.ShopModule)
  },
  
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes,{ scrollPositionRestoration: 'enabled', onSameUrlNavigation:'reload',  useHash: false,  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
