import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscriber, EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Response } from 'src/app/modals/response';
import { MenuModel } from 'src/app/modals/menu';
import { MatSnackBar } from '@angular/material';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { ApiProvider } from '../../shared/services/api';

const CACHE_SIZE = 1;
@Injectable({
  providedIn: 'root'
})
export class MenuService {
  public currency : string = 'TL';
  public catalogMode : boolean = false;
  BASE_URL: string;

  private _url: string = "assets/data/";
  public url = "assets/data/banners.json";
  private cache$: Observable<Response<MenuModel>>;
  
  constructor(public apiProvider:ApiProvider, private httpClient: HttpClient, public snackBar: MatSnackBar) {
  }

  get categoryList() {
    if (!this.cache$) {
      this.cache$ = this.requestCategoryList().pipe(
        shareReplay(CACHE_SIZE)
      );
    }

    return this.cache$;
  }

  public requestCategoryList():Observable<Response<MenuModel>>{
    return <Observable<Response<MenuModel>>>this.apiProvider.postPromise('/ListV2/Categories', {});
  }

 


     

}
