import { Directive,  OnInit, Renderer2, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appHighlight]'
})
export class IframeEventDirective implements OnInit {

  constructor(private el: ElementRef, private renderer: Renderer2) { 
    console.log("IFRAM IFRAME"); 
    this.el.nativeElement.style.backgroundColor = "yellow"; 
  }
  ngOnInit() {
    this.renderer.setStyle(this.el.nativeElement, 'color', 'yellow');
    console.log('Directive called');
  }
}
