import { Component, OnInit, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { Product } from 'src/app/modals/product.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-demand-dialog',
  templateUrl: './demand-dialog.component.html',
  styleUrls: ['./demand-dialog.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class DemandDialogComponent implements OnInit {
  product: any = {};
  member : any = {};
  frmContact : FormGroup;
  submitted : boolean = false;
  submitSuccess:boolean=false;

  public url; 
  public items; 
  @ViewChild('zoomImage', { static: true }) zoomImage;

  constructor( 
    private productsService: ProductService, 
    public formBuilder: FormBuilder, 
    public dialogRef: MatDialogRef<DemandDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {member, product}) {
      this.product = data.product;
      this.frmContact = formBuilder.group({
        nameSurname: [data.member.Firstname+" "+data.member.Lastname, [Validators.required]],
        message: ['', [Validators.required]]
      });
      console.log(data);
    }

  ngOnInit() {

  }

  
  onSend() {
    let { message } = this.frmContact.value;
    this.submitted = true;
 
    this.productsService.sendProductDemand(this.member.DataId,this.product.Id,message).subscribe(result=>{
      
      this.submitSuccess=true;
    });
  }

  public close(): void {
    this.dialogRef.close();
  }


}
