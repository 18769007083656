import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountProvider } from '../../shared/services/account';
import { AppComponent } from "src/app/app.component";
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
export interface ResultInterface {
  Result: any;  
  HasError: string;  
  Error: string;
}
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.sass']
})
export class RegisterComponent implements OnInit {
  registerForm : FormGroup;
  submitted: boolean = false;
  error: any;
  loggedIn: any;
  logg: any;
  checkorder: boolean = false;
  checkMessage: any = "";

  thisStudent: boolean = false;
  cityList: any[] = [];
  selectedCityId: number = 0;
  checkRight : boolean = false;
  checkKVKK : boolean = false;
  constructor(
    private fb: FormBuilder,
    public router: Router,
    public snackBar: MatSnackBar,
    private accountProvider: AccountProvider,
    public dialog: MatDialog
    ) { 

      this.logg = this.accountProvider.getUserInfo();

     
        this.loggedIn = this.logg ? 3 : 0;

        if(this.logg){
          AppComponent.memberId = this.logg.DataId;
          AppComponent.memberIsStudent = this.logg.IsStudent;  
          this.router.navigate(['/']);
          console.log(this.logg);
            
        } else { 
        }
   

      this.registerForm = this.fb.group({
        isStudent: [""],
        firstname: [
            "",
            Validators.compose([Validators.maxLength(30), Validators.required]),
        ],
        lastname: [
            "",
            Validators.compose([Validators.maxLength(30), Validators.required]),
        ],
        username: [
            "",
            Validators.compose([Validators.maxLength(30), Validators.required]),
        ],
        email: [
            "",
            Validators.compose([
            Validators.maxLength(30),
            Validators.required,
            Validators.email,
            ]),
        ],
        cityId: [""],
        mobile: [""],
        password: [
            "",
            Validators.compose([Validators.maxLength(12), Validators.required]),
        ],
        rePassword: ["", Validators.required],
        taxNumber: [""],
        taxOffice: [""],
        officeName: [""],
        officeAddress: [""],
        officePhone: [""],
        university: [""],
        expertCategory: [""],
        refNumber: [""],
      });

    }

  ngOnInit() {
    this.logg = this.accountProvider.getUserInfo();
    if(this.logg){ 
      this.router.navigate(['/']);
    } else {
       
    }
  }
  selecter(event) { 
    this.thisStudent = event.value; 
  }
  onLogin() {     
     var registerData = this.registerForm.value;
     if (registerData.password == registerData.rePassword) {
        if (registerData.mobile[0] == "0" && registerData.mobile.length == 11) { 
          registerData.platform = "web";
          registerData.deviceToken = "";
          registerData.isStudent = this.thisStudent;
          this.submitted = true;

          this.accountProvider.register(registerData).subscribe((result:ResultInterface) => {
            if (result.HasError) {
                this.snackBar.open(result.Error, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });            
            } else {
              //this.snackBar.open(result.Result.Message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });            
              this.registerForm.reset();
              this.checkMessage = result.Result.Message;
              this.checkorder = true;
            }
          });
        } else {
            this.snackBar.open('Lütfen cep telefonu numaranızı 0 ile başlayacak şekilde 11 hane olarak giriniz!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        }
      } else {
        this.snackBar.open('Girmiş olduğunuz şifreler aynı olmalıdır !', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      }   

  }

  openCheck(e) {
    console.log(e);
    this.dialog.open(DialogComponent, {
      data: {url: e},
      panelClass: 'zoom-dialog'
    });
  }
  
  checkValue(e) {
    e=!e;
  }

}
