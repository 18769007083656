import { Injectable } from '@angular/core';
import { Response } from 'src/app/modals/response';
import { Product } from '../../../modals/product.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, of, Subscriber} from 'rxjs';


import { map, filter, scan } from 'rxjs/operators';
import { ApiProvider } from '../../shared/services/api';
import { AccountProvider } from './account';
import { AppComponent } from "src/app/app.component";

// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("wishlistItem")) || [];

@Injectable({
  providedIn: 'root'
})
export class WishlistService {

  // wishlist array
  public wishlistProducts: BehaviorSubject<Product[]> = new BehaviorSubject([]);
  public observer   :  Subscriber<{}>;

  constructor(public apiProvider:ApiProvider, public accountProvider: AccountProvider, public snackBar: MatSnackBar) { }

    // Get  wishlist Products
  public getProducts()  { 
    var model = { 
      MemberId: AppComponent.memberId,
    };
    return this.apiProvider.postPromise("/ListV2/FavoriteProductList", model);
  }


   // If item is aleready added In wishlist
  public hasProduct(product: Product): boolean {
    const item = products.find(item => item.id === product.Id);
    return item !== undefined;
  }

   // Add to wishlist
   public addToWishlist(product: Product) {

    if(!this.accountProvider.getUserInfo()) {
      this.snackBar.open('Lütfen üye girişi yapınız', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
     } else {
        if(AppComponent.memberId>0){
          var model = {
            MemberId: AppComponent.memberId,
            ProductId: product.Id,
            IsFavorited: true,
          };
          return this.apiProvider.postPromise("/ProductV2/SetFav", model);
        }else{
          //return Observable.of("LoginRequired");
          return of("LoginRequired");
        }   
    } 
    //return item;
  }


  // Removed Product
  public removeFromWishlist(product: Product) { 
    if(AppComponent.memberId>0){
      var model = {
        MemberId: AppComponent.memberId,
        ProductId: product.Id,
        IsFavorited: false,
      };
      return this.apiProvider.postPromise("/ProductV2/SetFav", model);
    }else{
      //return Observable.of("LoginRequired");
      return of("LoginRequired");
    }
  }
}
