import { Component, OnInit, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'; 
import { AccountProvider } from 'src/app/components/shared/services/account';
import { Product } from 'src/app/modals/product.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export interface ResultInterface {
  Result: any;  
  HasError: string;  
  Error: string;
}
@Component({
  selector: 'app-forget-dialog',
  templateUrl: './forget-dialog.component.html',
  styleUrls: ['./forget-dialog.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class ForgetDialogComponent implements OnInit {
  product: any = {};
  member : any = {};
  forgetPassForm : FormGroup;
  submitted : boolean = false;
  submitSuccess:boolean=false;
  submitMessage: string;

  public url; 
  public items; 
  @ViewChild('zoomImage', { static: true }) zoomImage;

  constructor( 
    private accountProvider: AccountProvider, 
    public formBuilder: FormBuilder, 
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ForgetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {member, product}) {
      this.forgetPassForm = formBuilder.group({
        email: ['', Validators.compose([Validators.maxLength(30), Validators.required,Validators.email])]
      }); 
    }

  ngOnInit() {

  }

  
  onSend() {
    this.submitted=true;
    this.accountProvider.forgetPassword(this.forgetPassForm.value).subscribe((result:ResultInterface)=>{      
      if(result.HasError){        
        this.snackBar.open(result.Error, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      }else{
        this.submitSuccess = true;
        this.submitMessage = result.Result;
      }
    });   
  }

  public close(): void {
    this.dialogRef.close();
  }


}
