import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.sass']
})
export class AboutUsComponent implements OnInit {
public url: any;
  constructor(private route: ActivatedRoute, ) {
    this.route.url.subscribe(url => {
      console.log(url);      
      this.url = url[0].path;
    });
  }

  ngOnInit() {
  }

}
