import { Component, OnInit, ElementRef, ViewChild } from '@angular/core'; 
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CartService } from '../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
import { CartItem } from 'src/app/modals/cart-item';
import { ProductService } from '../../shared/services/product.service';
import { OrderProvider } from '../../shared/services/orderProvider';
import { AccountProvider } from '../../shared/services/account';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material';
import { AppComponent } from "src/app/app.component";
import { Title } from '@angular/platform-browser';
import { IframeComponent } from '../payment/iframe/iframe.component';
import { DialogComponent } from '../dialog/dialog.component';

export interface ResultInterface {
  Result: any;  
  HasError: string;  
  Error: string;
}

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.sass']
})
export class CheckoutComponent implements OnInit {
  @ViewChild('iframe') iframe: ElementRef;

  public cartItems: Observable<CartItem[]> = of([]);
  public buyProducts: CartItem[] = [];

  amount: number;
  payments: string[] = ['Create an Account?', 'Flat Rate'];
  paymantWay: string[] = ['Direct Bank Transfer', 'PayPal'];

  url: string = "about:blank";
  frameUrl: SafeResourceUrl; // any = "about:blank";

  addressList : any[] = [];
  memberId : string = "";
  memberDataId : number = 0;
  selectedBank : any={};
  selectedInstallment : any={};
  invoiceAddressId : number = 0;
  cargoAddressId : number = 0;
  isCredit: boolean = false;
  CreditAmount: number = 0;
  basketData: any = {
    MemberId :0,
    BasketId:"",
    Detail:[],
    BasketPrice:"",
    NetPrice:"",
    TaxPrice:"",
    MemberPoint:"",
    BasketWithPoint: ""
  };
  usePoint : boolean = false;
  checkRight : boolean = false;
  installment : number = 1;
  process: boolean = false;
  checkorder: boolean = false;

  iframeAddress: string;

  constructor(public sanitizer: DomSanitizer,    public dialog: MatDialog, public router: Router, private cartService: CartService,  private titleService: Title, private orderProvider: OrderProvider, public productService: ProductService, private accountProvider: AccountProvider, public snackBar: MatSnackBar) {
    this.titleService.setTitle('Sipariş Detayı - Depolaa');
   }

  ngOnInit() {
    

    this.frameUrl= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.cartItems = this.cartService.getItems();
    this.cartItems.subscribe(products => this.buyProducts = products); 


    this.cartService.getMemberBasket().subscribe( (item:ResultInterface) => {
      if(item.HasError) { 
        this.router.navigate(['/sepet']);
      } else { 
        this.basketData=item.Result;
      }
      console.log(item); 
      if(item.Result.BasketPrice == 0) {
        this.router.navigate(['/sepet']);
      }
      this.isCredit = item.Result.HasCredit;
      this.CreditAmount = item.Result.CreditAmount;
    });

    this.loadAddressList();
  }

    loadAddressList(){ 
      var result = this.accountProvider.getUserInfo(); 
      this.memberId = result.MemberId;
      this.memberDataId = result.DataId;

      this.accountProvider.getAddressList(this.memberId).subscribe((result:ResultInterface)=>{
        this.addressList = result.Result; 
        console.log(this.addressList);
      });     
    }
    addressChange(event) { 
      this.invoiceAddressId = event.value.Id; 
      this.iframeAddress = event.value.Address;
    }
    addressChange2(event) { 
      this.cargoAddressId = event.value.Id;
    }
    selecterBank(event) { 
      this.selectedBank = event.value;
      console.log(this.selectedBank);
    }
    selecterInstallment(event) { 
      this.selectedInstallment = event.value;
      
    }
    checkValue(name: any,event: any){
      //console.log('checkRight',this.checkRight,'usePoint',this.usePoint);      
      // if(name == 'checkRight') {
      //   console.log('checkRight',event);
      //   this.checkRight = event;
      // }
      // if(name == 'usePoint') {
      //   console.log('usePoint',event);
      //   this.usePoint = event;
      // }
    }

    payment(){
      if(this.selectedBank == 'credit') {
        this.snackBar.open('Kredili ödeme alınıyor lütfen bekleyiniz...', '', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        this.orderProvider.createPaymentRequest(AppComponent.memberId,this.invoiceAddressId,this.cargoAddressId,this.usePoint,this.selectedInstallment.Installment,this.selectedBank.Id,true).subscribe((result: ResultInterface)=>{
          //console.log("Kredili ödeme cevap",result.Result);
          setTimeout(()=>{ 
                     this.checkorder = true;
                     AppComponent.cartTot = result.Result.BasketPrice;
                     document.getElementsByClassName('mat-drawer-content')[0].scrollTo(0, 0);
               },3000);         
        });
      }
      else if(this.selectedBank && this.selectedBank.Id > 0 && this.selectedInstallment && this.selectedInstallment.Installment>0){
        document.getElementsByClassName('mat-drawer-content')[0].scrollTo(0, 0);
        this.frameUrl= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        this.snackBar.open('Ödeme ekranı oluşturuluyor...', '', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        
        this.orderProvider.createPaymentRequest(AppComponent.memberId,this.invoiceAddressId,this.cargoAddressId,this.usePoint,this.selectedInstallment.Installment,this.selectedBank.Id,false).subscribe((result: ResultInterface)=>{
          //this.frameUrl= this.sanitizer.bypassSecurityTrustResourceUrl(result.Result.PaymentUrl); 
          //this.process = true; 
          
          let dialogRef = this.dialog.open(IframeComponent, {
            data: result.Result.PaymentUrl,
            panelClass: 'payment-dialog',
            width:'500px',
            disableClose: true
          });
    
          dialogRef.afterClosed().subscribe(result => {
            //console.log(`Ödeme onayı: ${result}`);
            //this.checkorder = result;
          });          
        
        });


      }else{
        this.snackBar.open('Lütfen banka ve taksit seçeneğinizi belirleyiniz', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      }    
    }

    back() {
      this.process = false;
    }

    openCheck(e) {
      console.log(e);
      this.dialog.open(DialogComponent, {
        data: {
          url: e,
          name: AppComponent.member,
          address: this.iframeAddress,
          cart: this.basketData,
        },
        panelClass: 'zoom-dialog'
      });
    }

}
