import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountProvider } from '../../shared/services/account';
import { AppComponent } from "src/app/app.component"; 
import { OrderProvider } from '../../shared/services/orderProvider';
import { Router } from '@angular/router';
import { Product } from 'src/app/modals/product.model';
import { NgxSpinnerService } from "ngx-spinner";

export interface ResultInterface {
  Result: any;  
  HasError: string;  
  Error: string;
}
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.sass']
})
export class OrdersComponent implements OnInit {

 
    orders: any = [];

  loginForm : FormGroup;
  submitted: boolean = false;
  error: any;
  loggedIn: any;
  logg: any;
  constructor(
    private fb: FormBuilder,
    public router: Router,
    private orderProvider: OrderProvider,
    private accountProvider: AccountProvider, 
    private spinner: NgxSpinnerService
    ) { 

      this.logg = this.accountProvider.getUserInfo();

     
        this.loggedIn = this.logg ? 3 : 0;
        if(this.logg){
          AppComponent.memberId = this.logg.DataId;
          AppComponent.memberIsStudent = this.logg.IsStudent;  
          this.getOrderList();
            console.log(this.logg);
            
        } else {
          this.router.navigate(['/uye-girisi']);
        }
        

      this.loginForm = this.fb.group({
        email: ['', Validators.compose([Validators.maxLength(30), Validators.required,Validators.email])],
        password: ['', Validators.compose([Validators.maxLength(12), Validators.required])]
      });

    }

  ngOnInit() {
     
  }

  public getOrderList(){ 
      this.orderProvider.getOrderList(AppComponent.memberId).subscribe( (orderData: ResultInterface)=>{
        this.orders = orderData.Result; 
        console.log(this.orders); 
      }); 
  }

  onLogin() {     
     
    this.submitted=true;
    this.accountProvider.login(this.loginForm.value).subscribe( (result:ResultInterface)=>{
      if(result.HasError){        
        this.error = result.Error;
      }else{
        this.error = null;
        var memberId=result.Result.MemberId;
        AppComponent.memberId = result.Result.DataId;
        AppComponent.memberIsStudent =result.Result.IsStudent;
        console.log(result.Result);
        
        var setuser = this.accountProvider.setUserInfo(result.Result);
          if(setuser) {
            setTimeout(()=>{ 
              this.accountProvider.status(true,'active',memberId).subscribe(result=>{});
              this.router.navigate(['/pages/uye-girisi']);
            },750);
          }
      }
    });   

  }

}