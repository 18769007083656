import {Location} from '@angular/common';
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { NgxSpinnerService } from "ngx-spinner";
import { ProductService } from 'src/app/components/shared/services/product.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ProductZoomComponent } from './product-zoom/product-zoom.component';
import { AccountProvider } from 'src/app/components/shared/services/account';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppComponent } from "src/app/app.component";
import { Title, Meta } from '@angular/platform-browser';
import { DemandDialogComponent } from './dialog/demand-dialog.component';


export interface ResultInterface {
  Result: any;  
}

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.sass']
})
export class ProductDetailsComponent implements OnInit {

  public config: SwiperConfigInterface={};
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();

  @ViewChild('zoomViewer', { static: true }) zoomViewer;
  @ViewChild(SwiperDirective, { static: true }) directiveRef: SwiperDirective;

  public productParams : any;
  public product            :   Product = {};
  public products           :   Product[] = [];
  public loadingData: boolean = false;
  public image: any;
  public zoomImage: any;
   
  public counter            :   number = 1;

  public propSelected: boolean = false;
  public propDataList: any[] = [];
  public selectedProp: String = "";
  public selectedAttribute: String = "";
  title =   'detay | Depolaa';
  index: number;
  bigProductImageIndex = 0;

  public pageLink: string;
  currentUrl : string = "";
  constructor(
    private route: ActivatedRoute, 
    public productsService: ProductService, 
    public dialog: MatDialog, 
    private router: Router, 
    private cartService: CartService,
    private accountProvider: AccountProvider,
    public snackBar: MatSnackBar,
    private metaTagService: Meta,
    private titleService: Title,
    private location: Location,
    private spinner: NgxSpinnerService,
    ) 
  {
    console.clear();
    this.currentUrl = window.location.href;
    this.route.params.subscribe(params => {
      this.productParams = "" + params['product'];
      var splitted = this.productParams.split("-",20); 
      //console.log("ID ",parseInt(splitted[splitted.length-1]));
      this.spinner.show();
      this.productsService.getProductDetail(parseInt(splitted[splitted.length-1])).subscribe( (product: ResultInterface) => {
        this.product = product.Result;
        var labelList = [];
          this.product.PropertyList.forEach((element) => {
            if (labelList.indexOf(element.Label) == -1) {
              labelList.push(element.Label);
            }
          });
          labelList.forEach((x) => {
            var model = {
              Id: 0,
              Label: x,
              Value: [],
            };
            let subItems = this.product.PropertyList.filter(
              (c) => c.Label == x
            );
            subItems.forEach((element) => {
              model.Id = element.AttributeId;
              model.Value.push(element.Value);
            });
            this.propDataList.push({
              title: model.Label,
              attributeId: model.Id,
              subItems: model.Value,
            });
          });
          if (!this.product.PropSelection) {
            this.propSelected = true;
          }
          this.loadingData = true;

          this.titleService.setTitle(this.product.Name + ' ' + this.product.BrandName + ' | Depolaa');
          this.metaTagService.addTags([
            { name: 'keywords', content: 'depolaa, dentist alışveriş, diş hekimleri, öğrenciler, perakende' },
            { name: 'robots', content: 'index, follow' },
            { name: 'author', content: 'Depolaa' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { name: 'date', content: '2021-3-31', scheme: 'YYYY-MM-DD' },
            { charset: 'UTF-8' }
          ]);
          //console.log("Detail",this.product);
          this.spinner.hide();
      });
       
       
      //this.productsService.getProduct(id).subscribe(product => this.product = product)
    });

   this.pageLink = encodeURIComponent(this.location.path());
   }

   public addToCart(product: Product, quantity) {

    if (this.propSelected) {  
        const member = this.accountProvider.getUserInfo();
        if (member) {
          //var memberId = member.DataId;
          var memberId = member.MemberId;
          var productId = product.Id;
          
          // let letscart = this.cartService
          //   .addToCart(
          //     productId,
          //     "Add",
          //     this.selectedAttribute,
          //     this.selectedProp,
          //     quantity
          //   );

            this.cartService
            .addToCart(
              productId,
              "Add",
              this.selectedAttribute,
              this.selectedProp,
              quantity
            ).subscribe( (product: ResultInterface) => { 
              console.log(product);
              AppComponent.cartTot = product.Result.BasketPrice;
            });

            this.snackBar.open('Seçmiş olduğunuz ürün sepete eklendi', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        } else {
          this.snackBar.open('Lütfen üye girişi yapınız', '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
        }
  
    } else {
      this.snackBar.open('Bu ürünü sepete eklemeden önce özellik seçmeniz gerekmektedir', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 }); 
    }
   
  }

  ngOnInit() {
    
  }


  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },


      }
    };

    
  }

  selectProp(attributeId) {
    this.propSelected = true;
    this.selectedAttribute = attributeId;
  }

  public selectImage(index) {
    console.log(this.product)
    console.log(index)
    this.bigProductImageIndex = index;
  }




public increment() {
  this.counter += 1;
}

public decrement() {
  if(this.counter >1){
     this.counter -= 1;
  }
}
 

  



 public onMouseMove(e){
  if(window.innerWidth >= 1280){
    var image, offsetX, offsetY, x, y, zoomer;
    image = e.currentTarget;
    offsetX = e.offsetX;
    offsetY = e.offsetY;
    x = offsetX/image.offsetWidth*100;
    y = offsetY/image.offsetHeight*100;
    zoomer = this.zoomViewer.nativeElement.children[0];
    if(zoomer){
      zoomer.style.backgroundPosition = x + '% ' + y + '%';
      zoomer.style.display = "block";
      zoomer.style.height = image.height + 'px';
      zoomer.style.width = image.width + 'px';
    }
  }
}

public onMouseLeave(event){
  this.zoomViewer.nativeElement.children[0].style.display = "none";
}

public openZoomViewer(){
  this.dialog.open(ProductZoomComponent, {
    data: this.zoomImage,
    panelClass: 'zoom-dialog'
  });
}

sendDemand(pro) {
  
    let memberInfo = this.accountProvider.getUserInfo();
    
    let dialogRef = this.dialog.open(DemandDialogComponent, {
      data: {
        member: memberInfo,
        product: pro,
      },
      width:'500px',
      panelClass: 'payment-dialog',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      
      setTimeout(() => {
         
      }, 750);        
    }); 
}

}


