import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CartComponent } from './cart/cart.component';
import { ContactComponent } from './contact/contact.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { CheckoutComponent } from './checkout/checkout.component';

import { PagesRoutingModule } from './pages-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyAccountComponent } from './my-account/my-account.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { FaqComponent } from './faq/faq.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { PointsComponent } from './points/points.component';
import { PaymentComponent } from './payment/payment.component';
import { AddressComponent } from './address/address.component';
import { OrdersComponent } from './orders/orders.component';

import { DialogComponent } from './dialog/dialog.component';
import { PayDialogComponent } from './payment/dialog/paydialog.component';
import { ForgetDialogComponent } from './login/dialog/forget-dialog.component';
import { IframeComponent } from './payment/iframe/iframe.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    SharedModule,
  ],
  declarations: [
    CartComponent,
    ContactComponent,
    WishlistComponent,
    CheckoutComponent,
    MyAccountComponent,
    LoginComponent,
    RegisterComponent,
    FaqComponent,
    AboutUsComponent,
    PointsComponent,
    PaymentComponent,
    AddressComponent,
    OrdersComponent,
    ErrorPageComponent,
    DialogComponent,
    PayDialogComponent,
    ForgetDialogComponent,
    IframeComponent,
  ],
  exports: [ 
    DialogComponent,
    PayDialogComponent,
    ForgetDialogComponent,
    IframeComponent,
  ],

  entryComponents:[ 
    DialogComponent,
    PayDialogComponent,
    ForgetDialogComponent,
    IframeComponent,
  ],
})
export class PagesModule { }
