import { Component, OnInit, Input } from '@angular/core';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { Product } from 'src/app/modals/product.model';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { CartService } from 'src/app/components/shared/services/cart.service'; 
import { WishlistService } from 'src/app/components/shared/services/wishlist.service';
@Component({
  selector: 'app-product-deal',
  templateUrl: './product-deal.component.html',
  styleUrls: ['./product-deal.component.sass'],
  host: {'class': 'deal-of-week_wrap'}
})
export class ProductDealComponent implements OnInit {
  @Input('slides') slides: Array<any> = [];
  @Input() products: Product[];
  public config: SwiperConfigInterface = {};
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination-deal',
    clickable: true
  };
  constructor(private cartService: CartService, private wishlistService: WishlistService, private productService: ProductService ) { }
  ngOnInit() { console.log("slides ",this.slides) }
  
  ngAfterViewInit(){
    this.config = {
      autoHeight: false,
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: false,
      loop: true,
      preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false
      },
      speed: 500,
      effect: "slide"
    }
  }

  initialize() {

  }

  public addToCart(product: Product,  quantity: number = 1) {
    
  }
  
  public addToWishlist(product: Product) {
    if(product.IsFavorited) {
      this.wishlistService.removeFromWishlist(product).subscribe(item => {   
        console.log(item);
        product.IsFavorited = false;
      }); 
    } else {
      this.wishlistService.addToWishlist(product).subscribe(item => {   
        console.log(item);
        product.IsFavorited = true;
      }); 
    }    
  } 

}
