import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../shared/services/product.service';
import { Product } from 'src/app/modals/product.model';
import { Title, Meta } from '@angular/platform-browser';
//import { SlideModel } from 'src/app/modals/slides.model';


export interface ListInterface {
  Name: any;  
  List: any;  
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
  products: Product[];
  public banners = [];
  public slides: any = [];
  productTypeList: any[] = [];
  producVertical: any[] = [];
  producDiscount: ListInterface;

  constructor(
    private productService: ProductService,
    private metaTagService: Meta,
    private titleService: Title
    ) {  
      this.titleService.setTitle('Depolaa - Alışveriş Başlasın!');   
      this.metaTagService.addTags([
        { name: 'description', content: 'Sadece diş hekimlerine özel olarak tasarlanan Alışveriş, eğitim ve sosyal içerikli ilk platform.' },
        { name: 'robots', content: 'index, follow' },
        { name: 'author', content: 'Depolaa' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'date', content: '2021-3-31', scheme: 'YYYY-MM-DD' },
        { charset: 'UTF-8' }
      ]);
    }

  ngOnInit() {

    this.productService.getSlides(false,0)
    .subscribe(result => {
      if(result.hasError) {} else {
        this.slides = result.Result;
        console.log(result.Result);
      }       
    });

    this.productService.getProductListAllType().subscribe((res: any) => {
      if (!res.HasError) { 
        this.productTypeList = res.Result; 
        console.log(res.Result);
        this.producDiscount = this.productTypeList[0];
        this.producVertical = this.productTypeList.slice(1, 6);
      }
    });

  }

}