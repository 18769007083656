import { AppComponent } from "src/app/app.component";
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiProvider } from '../../shared/services/api';

@Injectable({
  providedIn: 'root'
})
export class OrderProvider {
  constructor(public apiProvider:ApiProvider) {
    
  }

  createPaymentRequest(memberId,invoiceId,cargoId,usePoint,installment,bankId,isCredit){
    let model = {
      MemberId:memberId,
      InvoiceId:invoiceId,
      CargoId:cargoId,
      UsePoint:usePoint,
      Installment:installment,
      BankId:bankId,
      IsCredit: isCredit
    };
    return this.apiProvider.postPromise('/OrderV2/CreatePayment', model);
  }

  getOrderList(memberId){
    let model = {
      MemberGuid: AppComponent.memberIdq, 
      MemberId : AppComponent.memberId
    };
    return this.apiProvider.postPromise('/OrderV2/OrderList', model);
  }

}
