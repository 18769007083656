import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountProvider } from '../../shared/services/account';
import { AppComponent } from "src/app/app.component";
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material';
import { PayDialogComponent } from './dialog/paydialog.component';
import { IframeComponent } from './iframe/iframe.component';
import { DialogComponent } from '../dialog/dialog.component';
import { OrderProvider } from '../../shared/services/orderProvider';
import * as CryptoJS from 'crypto-js';

export interface ResultInterface {
  Result: any;  
  HasError: string;  
  Error: string;
}
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.sass']
})
export class PaymentComponent implements OnInit {
  orders: any = [];
  loginForm : FormGroup;
  submitted: boolean = false;
  error: any;
  loggedIn: any;
  logg: any;
  memberPoint: any;
  selectedOrders : any[]=[];
  checkRight : boolean = false;
  checkorder: boolean = false;
  listLoading : boolean = true;
  constructor(
    private fb: FormBuilder,
    public router: Router,
    public snackBar: MatSnackBar,
    private orderProvider: OrderProvider,
    private accountProvider: AccountProvider,
    public dialog: MatDialog
    ) { 

      this.logg = this.accountProvider.getUserInfo();

     
        this.loggedIn = this.logg ? 3 : 0;
        if(this.logg){
          AppComponent.memberId = this.logg.DataId;
          AppComponent.memberIsStudent = this.logg.IsStudent;  
          this.memberPoint = AppComponent.points;
          this.getOrderList();
        } else {
          this.router.navigate(['/uye-girisi']);
        }
   

      this.loginForm = this.fb.group({
        email: ['', Validators.compose([Validators.maxLength(30), Validators.required,Validators.email])],
        password: ['', Validators.compose([Validators.maxLength(12), Validators.required])]
      });

    }

  ngOnInit() {
  }

  public getOrderList(){ 
    this.orderProvider.getOrderList(AppComponent.memberIdq).subscribe( (orderData: ResultInterface)=>{
      this.orders = orderData.Result;
      this.orders = this.orders.filter((x)=>{
        return x.Header.IsCredit && !x.Header.IsPayment
      });
      this.listLoading = false;
    }); 
}
 
  totalAmount(){
    var totalAmount = 0;
    this.orders.forEach(element => {
      totalAmount += Number(element.Header.Price.split(' ')[0].replace('.','').replace(',','.'));
    });
    return totalAmount;
  }

  totalSelected(){
    var totalAmount = 0;
    this.selectedOrders.forEach(element => {
      var xelement =this.orders.filter(x=> x.Header.OrderNo==element);
      totalAmount += Number(xelement[0].Header.Price.split(' ')[0].replace('.','').replace(',','.'));
    });
    return totalAmount;
  }

  showOrderDetail(orderItem){
    this.dialog.open(PayDialogComponent, {
      data: orderItem,
      panelClass: 'zoom-dialog'
    });
  }

  createPaymentParameter(){

  }

  selectOrder(orderNo){
    if(this.selectedOrders.indexOf(orderNo)==-1){
      this.selectedOrders.push(orderNo);
    }else{
      this.selectedOrders = this.selectedOrders.filter(x=> x!==orderNo);
    }
  }

  setPayment(){
    if(this.selectedOrders.length<1){
      this.snackBar.open('Lütfen ödemesini yapacağınız siparişleri seçiniz !', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
    }else{
      var ordersJSON =  JSON.stringify(this.selectedOrders);      
      var ordersParam = this.encryptData(ordersJSON);
      var url = 'https://odeme.depolaa.com/Payment/Index?q='+ ordersParam;
      //url = 'http://localhost:856/Payment/Index?q='+ ordersParam;
      console.clear();
      console.log(url);
      let dialogRef = this.dialog.open(IframeComponent, {
        data: url,
        width:'500px',
        panelClass: 'payment-dialog',
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        this.listLoading = true;
        this.orders = [];
        setTimeout(() => {
          this.getOrderList();  
        }, 750);        
      });

    }
  }

  encryptData(dataSource){
    var pass = "WDT|DepolaAaA.2o21!!";    
    // var key = CryptoJS.enc.Utf8.parse('8080808080808080');  
    // var iv = CryptoJS.enc.Utf8.parse('8080808080808080');    
    // var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(dataSource), key,  
    // {  
    //   keySize: 128 / 8,  
    //   iv: iv,  
    //   mode: CryptoJS.mode.CBC,  
    //   padding: CryptoJS.pad.Pkcs7  
    // }); 
    return btoa(dataSource);
  }

  openCheck(e) {
    console.log(e);
    this.dialog.open(DialogComponent, {
      data: {
        url: e,
        name: AppComponent.member,
        address: "",
        cart: {
          BasketPrice: this.totalSelected(),
        },
      },
      panelClass: 'zoom-dialog'
    });
  }

}