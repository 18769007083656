import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from "@angular/forms";
import { Observable, of } from 'rxjs';
import { Product } from 'src/app/modals/product.model';
import { CartItem } from 'src/app/modals/cart-item';
import { CartService } from '../services/cart.service';
import { SidebarMenuService } from '../sidebar/sidebar-menu.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AccountProvider } from '../services/account';  
import { AppComponent } from "src/app/app.component";
import { Router } from '@angular/router';

export interface ResultInterface {
  Result: any;  
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  public sidenavMenuItems:Array<any>;

  @ViewChild("search") search: any;
  searchTerm: string = "";
  private searchControl: FormControl;
  private debounce: number = 400;
  items: any;
  searching: any = false;
  logg: any = [];
  isCredit: boolean = false;

  history: Array<{ Id: any; Name: string; ParentId: any; Type: string }>;
  public currencies = ['TL', 'EUR'];
  public currency:any;
  public flags = [
    { name:'Turkish', image: 'assets/images/flags/tr.svg' }
  ]
  public flag:any;

  products: Product[];
  memberPoints: any;
  indexProduct: number;
  shoppingCartItems: CartItem[] = [];


  constructor(public accountProvider: AccountProvider, public router: Router, private cartService: CartService) { 
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    this.logg = this.accountProvider.getUserInfo();
    console.log(this.logg);
  }

  getSearchUrl(option){
    var urlInfo = [];
    option.Url.split('/').forEach(element => {
        urlInfo.push(element);
    });
    return urlInfo;
  }

  getSearchProductUrl(option){
    var productUrl : string[]=[];
    option.Url.split('/').forEach(element => {
      productUrl.push(element);
    });
    return productUrl;
  }

  ngOnInit() {
    this.currency = this.currencies[0];
    this.flag = this.flags[0];
    this.searchControl = new FormControl();
    this.history = [];


    setTimeout(() => {
      console.log(AppComponent.memberIsCredit);
      this.memberPoints = AppComponent.points;
      this.isCredit = AppComponent.memberIsCredit;
    }, 1000); 

    this.searchControl.valueChanges
      .pipe(debounceTime(this.debounce), distinctUntilChanged())
      .subscribe(query => {
        console.log(query);
      });

    // this.searchControl.valueChanges.debounceTime(700).subscribe((search) => {
    //   this.accountProvider
    //     .search(this.searchTerm, member.DataId)
    //     .subscribe((result) => {
    //       this.searching = false;
    //       this.items = result.Result;
    //     });
    // });

  }

  public changeCurrency(currency){
    this.currency = currency;
  }
  public changeLang(flag){
    this.flag = flag;
  }
  onSearchChange(searchValue: string): void {  
    console.log(searchValue);
    this.items = [];
    this.accountProvider
    .search(searchValue, 0)
    .subscribe((result:ResultInterface) => {
      this.searching = false;
      this.items = result.Result;
      console.log(result.Result);
    });    
  }

  onBlurMethod() {
    setTimeout(() => {
      this.searchTerm = "";
      this.items = [];
    }, 500);   
  }
  // onSearchInput() {
  //   this.searching = true;
  // }
  logout() {
    setTimeout(() => {
      this.accountProvider.logout();
      window.location.reload();
    }, 1000);
  }

}